<template>
  <div>
    <v-expand-transition>
      <CustomerFilters v-show="showFilters" @clearFilterEvent="onClearFilterEvent"></CustomerFilters>
    </v-expand-transition>
    <v-data-table
        :headers="headers"
        :items="customers"
        :search="search"
        :loading="!!isLoading"
        :loading-text="$t('loading')"
        :sort-by="sortlist"
        :sort-desc="[true]"
        class="elevation-1"
        multi-sort
        :item-class="itemRowBackground"
        :items-per-page="itemsxpage"
        :footer-props="{ showFirstLastPage: true, firstIcon: 'mdi-page-first', lastIcon: 'mdi-page-last', prevIcon: 'mdi-chevron-left', nextIcon: 'mdi-chevron-right' }"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title v-t="'customers'"></v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn color="info" dark class="mb-2" @click="downloadOrderListExport()">
            <v-icon>mdi-microsoft-excel</v-icon>
          </v-btn>
          <v-btn color="info" dark class="mb-2" @click="showFilters = !showFilters">
            <v-icon>mdi-filter</v-icon>
          </v-btn>
          <v-btn color="primary" dark class="mb-2" to="addCustomer" v-t="'add'"></v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.active="{ item }">
        <v-menu v-model="menu[item.id]" :close-on-content-click="false" :nudge-width="200" offset-x>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-img class="mini-img" v-if="!!item.potential" src="../../assets/pitto-giallo-MyFamily.png"></v-img>
              <v-img class="mini-img" v-else-if="!item.active" src="../../assets/pitto-rosso-MyFamily.png"></v-img>
              <v-img class="mini-img" v-else src="../../assets/pitto-verde-MyFamily.png"></v-img>
            </div>
          </template>
          <v-card>
            <v-list>
              <v-list-item two-line v-if="!!item.shopName">
                <v-list-item-avatar>
                  <v-icon>mdi-shopping-outline</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ $t('shopName', {r:''}) }}</v-list-item-subtitle>
                  <v-list-item-title>{{ item.shopName }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-avatar>
                  <v-icon>mdi-calendar</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle v-t="'registrationDate'"></v-list-item-subtitle>
                  <v-list-item-title>{{ item.updateTimestamp | formatDate }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-avatar>
                  <v-icon>mdi-calendar</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle v-t="'activationDate'"></v-list-item-subtitle>
                  <v-list-item-title>{{ item.activationDate | formatDate }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-avatar>
                  <v-icon>mdi-at</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ $t('email', {r:''}) }}</v-list-item-subtitle>
                  <v-list-item-title>{{ item.email || 'MISSING' }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-avatar>
                  <v-icon>mdi-numeric</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ $t('vatNumber', {r:''}) }}</v-list-item-subtitle>
                  <v-list-item-title>{{ item.vatNumber || 'MISSING' }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line v-if="!!item.hasEngravers">
                <v-list-item-avatar>
                  <v-icon>mdi-diamond-stone</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle v-t="'hasEngravers'"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </template>
      <template v-slot:item.prestashopB2BCustomerId="{ item }">
        <v-icon v-if="item.prestashopB2BCustomerId" color="green">mdi-check-circle</v-icon>
        <v-icon v-else color="red">mdi-close-circle</v-icon>
      </template>
      <template v-slot:item.code="{ item }">
        <div @click="redirectTo(item)" class="pointer">
          {{ item.code }}
        </div>
      </template>
      <template v-slot:item.businessName="{ item }">
        <div @click="redirectTo(item)" class="pointer">
          {{ `${item.businessName}${!!item.shopName ? ` [${item.shopName}]` : ``}` }}
        </div>
      </template>
      <template v-slot:item.parentCode="{ item }">
        <div @click="redirectToCode(item.parentCode)" class="pointer">
          {{ item.parentCode ? item.parentCode + " - " + getCustomerName(item.parentCode) : "" }}
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <MFButtonPill class="float-right" :collection="getButtons(item)"></MFButtonPill>
      </template>
      <template v-slot:no-data>
        <NoDataImg/>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline">{{$t('deleteText', {param:$t('customer')})}}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete" v-t="'cancel'"></v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm" v-t="'confirm'"></v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { call, get, sync } from "vuex-pathify";
import NoDataImg from "../../components/NoDataImg.vue";
import CustomerFilters from "../../components/filters/CustomerFilters";
import MFButtonPill from "../../components/utility/MFButtonPill.vue";

export default {
  components: {
    NoDataImg,
    CustomerFilters,
    MFButtonPill
  },
  data: () => ({
    options: {
      itemsPerPage: 10,
      sortBy: ["Code"]
    },
    menu: {},
    validForm: false,
    birthday: false,
    address: "",
    emptyCollection: [],
    paymentType: [],
    parents: [],
    vatRates: [],
    languages: [],
    leadstatusCollection: [],
    model: null,
    select: {
      newsletter: ["Yes", "No"],
      merch: ["totem 55", "totem 36", "old merch"]
    },
    panel: null,
    disabled: null,
    overlay: false,
    photos: [
      {
        src: "https://cdn.webshopapp.com/shops/3533/files/325709596/gevel-purrfect-design.jpg"
      },
      {
        src: "https://s3-media0.fl.yelpcdn.com/bphoto/2QYOto7YApFyfO9PNcKLSg/o.jpg"
      },
      {
        src: "https://s3-media0.fl.yelpcdn.com/bphoto/RFX2yPOpV_qDIYjzB8azHQ/l.jpg"
      }
    ],
    value: [423, 446, 675, 510, 590, 610, 760],
    events: [],
    input: null,
    nonce: 0,
    editTabs: null,
    editTabItems: ["Data", "Marketing", "Contacts"],
    tabs: null,
    tabItems: ["Data", "Marketing", "Tabels", "Note", "Contacts"],
    notifications: false,
    sound: true,
    widgets: false,
    dialog: false,
    dialogDelete: false,
    dialogView: false,
    search: "",
    editedIndex: -1,
    viewedItem: {},
    editedItem: {},
    defaultItem: {}
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Customer" : "Edit Customer";
    },
    timeline() {
      return this.events.slice().reverse();
    },
    sortlist() {
      return this.customers.length < 50 ? ["code"] : [];
    },
    itemsxpage() {
      return this.customers.length < 50 ? 50 : 10;
    },
    nameRules() {
      return [
        v => !!v || this.$t("required", { param: this.$t("name") }),
        v => (v || "").length <= 50 || "Name must be less than " + v.length + " characters"
      ];
    },
    emailRules() {
      return [
        v => !!v || this.$t("required", { param: this.$t("email", { r: "" }) }),
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t("valid", { param: this.$t("email", { r: "" }) })
      ];
    },
    emailBisRules() {
      return [
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t("valid", { param: this.$t("email", { r: "" }) })
      ];
    },
    phoneRules() {
      return [
        v => !!v || this.$t("required", { param: this.$t("phone") }),
        v => ("" + v).length > 8 || this.$t("required", { param: this.$t("phone") })
      ];
    },
    headers() {
      return [
        { text: this.$t("status"), value: "active" },
        { text: "B2B", value: "prestashopB2BCustomerId" },
        { text: this.$t("code"), value: "code" },
        { text: this.$t("businessName", { r: "" }), value: "businessName" },
        { text: this.$t("email", { r: "" }), value: "email" },
        { text: this.$t("parent"), value: "parentCode" },
        { text: this.$t("actions"), value: "actions", sortable: false, align: "center" }
      ]
    },
    customers: sync("customers/customers"),
    allCustomers: sync("customers/allCustomers"),
    agentsDropdown: get("agents/dropdownActiveAgents"),
    commercialSpecialistsDropdown: get("commercialSpecialists/dropdownCommercialSpecialists"),
    customerOwnsership: get("customers/ownsership"),
    isLoading: sync("utils/isLoading"),
    showFilters: sync("customers/showFilters")
  },
  watch: {
    dialogView(val) {
      val || this.closeView();
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    itemRowBackground(item) {
      return (item.active || item.potential) ? "" : "striped";
    },
    getPic() {
      return !!this.editedItem && !!this.editedItem.picture ? this.editedItem.picture : require("@/assets/profile_empty.svg");
    },
    getButtons(record) {
      let tempCollection = [
        {
          icon: "mdi-pencil",
          color: "primary",
          action: () => this.redirectTo(record)
        },
        {
          icon: "mdi-source-branch-plus",
          color: "secondary",
          action: () =>
              this.showConfirm({
                message: this.$t("pleaseConfirm"),
                action: this.cloneItem,
                params: record
              })
        }
      ]
      return tempCollection;
    },
    redirectTo(item) {
      this.$router.push("customers/" + item.id);
    },
    redirectToCode(code) {
      this.$router.push("customers/" + this.allCustomers.find(el => el.code == code)?.id);
    },
    comment() {
      const time = new Date().toTimeString();
      this.events.push({
        id: this.nonce++,
        text: this.input,
        time: time.replace(/:\d{2}\sGMT-\d{4}\s\((.*)\)/, (match, contents) => {
          return ` ${contents.split(" ").map(v => v.charAt(0)).join("")}`
        })
      })
      this.input = null;
    },
    getAddressData(addressData, placeResultData, id) {
      this[id].address = !!placeResultData ? placeResultData.formatted_address : "";
      this[id].locationUrl = placeResultData.url;
      this[id].utcOffset = placeResultData.utc_offset_minutes;
      this[id].city = addressData.locality;
      this[id].postalCode = addressData.postal_code;
      this[id].geometry = {
        type: "Point",
        coordinates: [addressData.latitude, addressData.longitude]
      };
      this.getGeoNames(addressData.administrative_area_level_2)
          .then(res => {
            if (!!res) {
              let response = ((res.geonames || []).find(el => el.fcode == "ADM2") || {})
              this[id].state = response.name
              this[id].country = response.countryName
              this[id].geonameId = response.geonameId
            }
          })
          .catch(res => console.warn("Richiesta di informazioni a GeoNames fallita : " + res))
    },
    initialize() {
      this.initCustomerCollection();
      this.initAgentCollection();
      this.initCommercialSpecialistCollection();
    },
    onClearFilterEvent() {
      this.showFilters = false;
      this.initCustomerCollection(true);
    },
    viewItem(item) {
      this.editedIndex = this.customers.indexOf(item);
      this.viewedItem = Object.assign({}, item);
      this.customerOwnershipGet(item);
      this.dialogView = true;
    },
    editItem(item) {
      this.editedIndex = this.customers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.customerOwnershipGet(item);
      this.address = item.address;
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.customers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    cloneItem(item) {
      this.apiCustomersIdDuplicatePost(item.id).then(res => {
        this.$window.location.assign(`/customers/${res.id}`);
      });
    },
    deleteItemConfirm() {
      this.apiCustomersIdDelete(this.editedItem.id).finally(() => this.closeDelete());
    },
    closeView() {
      this.dialogView = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      })
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      })
    },
    save() {
      if (this.editedIndex > -1) {
        this.apiCustomersIdPut(this.editedItem);
      } else {
        this.apiCustomersPost(this.editedItem);
      }
      this.close();
    },
    getCustomerName(customerCode) {
      return (this.allCustomers.find(el => el.code === customerCode) || {}).businessName || "";
    },
    downloadOrderListExport() {
      const customerIds = this.customers.map(x => x.id);
      this.apiCustomersListExport(customerIds).then(res => {
        this.downloadExcello(`customers_export`, res);
      })
    },
    downloadExcello(filename, content) {
      var binaryData = [];
      binaryData.push(content);
      const url = window.URL.createObjectURL(new Blob(binaryData));
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `${filename}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    },
    ...call("customers/*"),
    ...call("utils/*"),
    ...call("app/*"),
    ...call("agents/*"),
    ...call("commercialSpecialists/*"),
    ...call("customerOwnerships/*")
  }
}
</script>

<style>
.label {
  font-size: 15px;
  color: grey;
}
.striped {
  background: repeating-linear-gradient(135deg, #eeeeee, #ffffff 10px, #eeeeee 10px, #ffffff 20px);
}
</style>
